<template>
  <Layout>
    <template v-if="this.$route.name == 'add-categories'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard
              @on-complete="submitData"
              color="#556ee6"
              enctype="multipart/form-data"
              ref="expert"
              :finishButtonText="
                this.$route.name == 'add-categories'
                  ? 'Save Data'
                  : 'Update Data'
              "
              back-button-text="Go Back!"
              next-button-text="Go Next!"
            >
              <tab-content
                title="Category Details"
                icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()"
              >
                <div class="row">
                  <b-form-group class="col-6">
                    <label for="input-2"
                      >Category Name <span style="color: red">*</span></label
                    >
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      @keyup.prevent="slugify"
                      placeholder="Enter Page Name"
                      :class="{
                        'is-invalid': submitted && $v.form.name.$invalid,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.form.name.required"
                      class="invalid-feedback"
                    >
                      Page Name is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="post_name">Category Slug </label>
                    <b-form-input id="post_name" v-model="form.slug" placeholder="Enter slug"></b-form-input>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group
                    label="Thumbnail Image [Upload Max File Size : 2MB]"
                    label-for="im"
                    class="col-6"
                  >
                    <b-form-file
                      id="im"
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'image')"
                      ref="image"
                    ></b-form-file>
                    <template
                      v-if="$route.name == 'edit-categories' && edit.image_url"
                    >
                      <img
                        :src="edit.image_url"
                        width="128px"
                        height="128px"
                      />
                    </template>
                    <template v-if="image_url">
                      <img
                        :src="image_url"
                        width="128px"
                        height="128px"
                        ref="image_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import categoryMixin from "../../../../mixins/ModuleJs/medi-blog-categories";
import { required } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  mixins: [MixinRequest, categoryMixin],
  data() {
    return {
      submitted: false,
      store: false,
      title1: "Add Medisage Blog Category",
      title2: "Edit Medisage Blog Category",
      items: [
        {
          text: "List",
          href: "/medi-blog/categories",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      name: { required }
    },
  },
  components: {
    PageHeader,
    Layout,
    FormWizard,
    TabContent,
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.name.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return true;
    },
  },
};
</script>
